//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DataAssetCategory {
  TABLE = 0,
  VIEW = 1,
  DERIVED_VIEW = 2,
  CUSTOM_REPORT = 3
}
